import React, { useState } from 'react';
import { GoTriangleUp, GoTriangleDown } from "react-icons/go";
import { FaAngleUp } from "react-icons/fa6";
import { IoEllipsisHorizontal } from "react-icons/io5";
import Constants from '../constants';
import Holdings, { Holding } from '../types/Holding';
import ImpactScore from './ImpactScore';

type Props = {
  holdings: Holdings
};

const HoldingsTable = (props: Props) => {

  const [holdings, setHoldings] = useState(props.holdings);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [page, setPage] = useState(1);
  const [holdingsPerPage, setHoldingsPerPage] = useState(10);

  const getPaginatedHoldings = () => {
    const start = holdingsPerPage * (page - 1);
    const end = start + holdingsPerPage;
    return holdings.slice(start, end);
  };

  const sortHoldings = (column: string) => {
    let newSortDirection = 'asc';

    if (column === sortColumn) {
      if (!sortDirection || sortDirection === 'desc') {
        newSortDirection = 'asc';
      } else {
        newSortDirection = 'desc';
      }
    } else {
      setSortColumn(column);
    }

    if (column === 'name') {
      if (newSortDirection === 'asc') {
        setHoldings(props.holdings.sort((a: Holding, b: Holding) => a.name.localeCompare(b.name)));
      } else {
        setHoldings(props.holdings.sort((a: Holding, b: Holding) => b.name.localeCompare(a.name)));
      }
    } else if (column === 'score') {
      if (newSortDirection === 'asc') {
        setHoldings(props.holdings.sort((a: Holding, b: Holding) => (a.score ?? 0) - (b.score ?? 0)));
      } else {
        setHoldings(props.holdings.sort((a: Holding, b: Holding) => (b.score ?? 0) - (a.score ?? 0)));
      }
    } else if (column === 'assetPercentage') {
      if (newSortDirection === 'asc') {
        setHoldings(props.holdings.sort((a: Holding, b: Holding) => a.assetPercentage - b.assetPercentage));
      } else {
        setHoldings(props.holdings.sort((a: Holding, b: Holding) => b.assetPercentage - a.assetPercentage));
      }
    } else {
      if (newSortDirection === 'asc') {
        setHoldings(props.holdings.sort((a: Holding, b: Holding) => a.ticker.localeCompare(b.ticker)));
      } else {
        setHoldings(props.holdings.sort((a: Holding, b: Holding) => b.ticker.localeCompare(a.ticker)));
      }
    }

    setSortDirection(newSortDirection);
  };

  return (
    <div className="mt-8">
      <div className="border-l border-r border-zinc-300">

        <div className='bg-blue'>
          <div className='text-white text-lg font-bold py-3 pl-3'>Holdings</div>
        </div>

        <div className='flex bg-slate-50 cursor-pointer'>
          <HeaderColumn basis='1/5' displayName='Ticker' sortKey='ticker' sortColumn={sortColumn} sortDirection={sortDirection} sortHoldings={sortHoldings} />
          <HeaderColumn basis='2/5' displayName='Name' sortKey='name' sortColumn={sortColumn} sortDirection={sortDirection} sortHoldings={sortHoldings} />
          <HeaderColumn basis='1/5' displayName='Impact Score' sortKey='score' sortColumn={sortColumn} sortDirection={sortDirection} sortHoldings={sortHoldings} />
          <HeaderColumn basis='1/5' displayName='Asset %' sortKey='assetPercentage' sortColumn={sortColumn} sortDirection={sortDirection} sortHoldings={sortHoldings} />
        </div>

        {
          getPaginatedHoldings().map((holding: Holding, index: number) => {
            return (
              <a data-cy="holding-row" className='flex bg-white hover:bg-slate-100 transition-colors duration-300 ease-in-out border-b border-zinc-300' key={index} href={`/${holding.ticker}/${holding.country}`}>
                <div className='w-1/5 recta-cond text-lg py-4 px-2 sm:px-8'>{ holding.ticker }</div>
                <div className='w-2/5 recta-cond text-lg py-4 px-2 sm:px-8'>{ holding.name }</div>
                <div className='w-1/5 py-4 px-2 sm:px-8'>
                  <ImpactScore score={holding.score} size="small" />
                </div>
                <div className='w-1/5 recta-cond text-lg py-4 px-2 sm:px-8'>{ holding.assetPercentage }%</div>
              </a>
            );
          })
        }
      </div>

      <Pagination
        holdings={props.holdings}
        holdingsPerPage={holdingsPerPage}
        setHoldingsPerPage={setHoldingsPerPage}
        page={page}
        setPage={setPage}
      />

    </div>
  );
};

type HeaderColumnProps = {
  basis: string,
  displayName: string,
  sortKey: string,
  sortColumn: string,
  sortDirection: string,
  sortHoldings: (column: string) => void
};

const HeaderColumn = (props: HeaderColumnProps) => {
  return (
    <div onClick={() => props.sortHoldings(props.sortKey)} className={`w-${props.basis} flex justify-between p-2 sm:px-8 border-b border-zinc-300`}>
      <div className='recta-cond'>{props.displayName}</div>
      <div className='flex flex-col'>
        <GoTriangleUp size={14} color={props.sortColumn === props.sortKey && props.sortDirection === 'asc' ? Constants.colors.blue : 'silver'} />
        <GoTriangleDown size={14} color={props.sortColumn === props.sortKey && props.sortDirection === 'desc' ? Constants.colors.blue : 'silver'} />
      </div>
    </div>
  );
};

type PaginationProps = {
  holdings: Holdings,
  holdingsPerPage: number,
  setHoldingsPerPage: (holdings: number) => void,
  page: number,
  setPage: (page: number) => void
};

const Pagination = (props: PaginationProps) => {

  const holdingsPerPageOptions = [10, 20, 50, 100];
  const [showDropdown, setShowDropdown] = useState(false);

  const getTotalPages = () => {
    return Math.ceil(props.holdings.length / props.holdingsPerPage);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onBlurDropdown = (event: any) => {
    if (!event.relatedTarget?.id.includes('dropdown')) {
      setShowDropdown(false);
    }
  };

  const selectHoldingsPerPage = (holdings: number) => {
    props.setHoldingsPerPage(holdings);
    setShowDropdown(false);
    props.setPage(1);
  };

  return (
    <div className='flex justify-end py-4'>
      <button className={`recta-cond text-md border-zinc-300 hover:border-blue hover:text-blue transition-colors duration-300 ease-in-out border w-8 h-8 ml-2 ${props.page === 1 ? 'text-zinc-300 hover:text-zinc-300 hover:border-zinc-300' : ''}`} disabled={props.page === 1} onClick={() => props.setPage(props.page - 1)}>{'<'}</button>
      {
        props.page > 3 &&
        <>
          <button className='recta-cond text-md border-zinc-300 hover:border-blue hover:text-blue transition-colors duration-300 ease-in-out border w-8 h-8 ml-2' onClick={() => props.setPage(1)}>{ 1 }</button>
          {
            props.page > 4 &&
            <div className='w-8 h-8 ml-2 flex justify-center items-center'>
              <IoEllipsisHorizontal size={24} color='silver' />
            </div>
          }
        </>
      }
      {
        props.page > 2 &&
        <button className='recta-cond text-md border-zinc-300 hover:border-blue hover:text-blue transition-colors duration-300 ease-in-out border w-8 h-8 ml-2' onClick={() => props.setPage(props.page - 2)}>{ props.page - 2 }</button>
      }
      {
        props.page > 1 &&
        <button className='recta-cond text-md border-zinc-300 hover:border-blue hover:text-blue transition-colors duration-300 ease-in-out border w-8 h-8 ml-2' onClick={() => props.setPage(props.page - 1)}>{ props.page - 1 }</button>
      }
      <button className='recta-cond text-md border-blue border w-8 h-8 ml-2 text-blue'>{ props.page }</button>
      {
        getTotalPages() - props.page >= 1 &&
        <button className='recta-cond text-md border-zinc-300 hover:border-blue hover:text-blue transition-colors duration-300 ease-in-out border w-8 h-8 ml-2' onClick={() => props.setPage(props.page + 1)}>{ props.page + 1 }</button>
      }
      {
        getTotalPages() - props.page >= 2 &&
        <button className='recta-cond text-md border-zinc-300 hover:border-blue hover:text-blue transition-colors duration-300 ease-in-out border w-8 h-8 ml-2' onClick={() => props.setPage(props.page + 2)}>{ props.page + 2 }</button>
      }
      {
        getTotalPages() - props.page >= 3 &&
        <>
          {
            getTotalPages() - props.page >= 4 &&
            <div className='w-8 h-8 ml-2 flex justify-center items-center'>
              <IoEllipsisHorizontal size={24} color='silver' />
            </div>
          }
          <button className='recta-cond text-md border-zinc-300 hover:border-blue hover:text-blue transition-colors duration-300 ease-in-out border w-8 h-8 ml-2' onClick={() => props.setPage(getTotalPages())}>{ getTotalPages() }</button>
        </>
      }
      <button className={`recta-cond text-md border-zinc-300 hover:border-blue hover:text-blue transition-colors duration-300 ease-in-out border w-8 h-8 ml-2 ${props.page === getTotalPages() ? 'text-zinc-300 hover:text-zinc-300 hover:border-zinc-300' : ''}`} disabled={props.page === getTotalPages()} onClick={() => props.setPage(props.page + 1)}>{'>'}</button>

      {
        props.holdings.length > holdingsPerPageOptions[0] &&
        <div className='ml-4 relative'>
          <button className='h-8 px-1 border-zinc-300 border flex items-center' onClick={() => setShowDropdown(!showDropdown)} onBlur={(event) => onBlurDropdown(event)}>
            <span className="mr-2 whitespace-nowrap">{props.holdingsPerPage} / page</span>
            <FaAngleUp size={14} />
          </button>
          { showDropdown &&
            <div className='absolute bg-white border-zinc-300 border-x border-t w-full bottom-8'>
              {
                holdingsPerPageOptions.map((option: number) => {
                  return (
                    props.holdings.length >= option &&
                    <button id={`dropdown-${option}`} key={option} onClick={() => selectHoldingsPerPage(option)} className='cursor-pointer hover:bg-slate-100 py-1 px-1 w-full'>{option} / page</button>
                  );
                })
              }
            </div>
          }
        </div>
      }
    </div>
  );
};

export default HoldingsTable;
