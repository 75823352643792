import React from 'react';
import Footer from '../../components/Footer';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import Issues, { NegativeIssues, PositiveIssues } from '../../types/Issues';
import spinner from '../../images/dual-ring-loading-grey.svg';
import logo from '../../images/inspire-insight-logo.svg';
import SearchBar from '../../components/SearchBar';
import NegativeIssueRow from '../../components/NegativeIssueRow';
import PositiveIssueRow from '../../components/PositiveIssueRow';
import HoldingsTable from '../../components/HoldingsTable';
import Holdings from '../../types/Holding';
import ImpactScore from '../../components/ImpactScore';
import Stock from '../../types/Stock';
import api from '../../utilities/api';

const Instrument = () => {

  const [stock, setStock] = useState({} as Stock);
  const [issues, setIssues] = useState({} as Issues);
  const [holdings, setHoldings] = useState({} as Holdings);
  const [loadedStock, setLoadedStock] = useState(false);
  const [loadedIssues, setLoadedIssues] = useState(false);
  const [loadedHoldings, setLoadedHoldings] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const urlSegments = window.location.href.split("/");
  const ticker = urlSegments[3];
  const country = urlSegments.length > 4 ? urlSegments[4] : null;

  useEffect(() => {
    const getStockAndHoldings = async () => {

      let isFund = false;

      try {
        let instrumentResponse;
        if (country === null) {
          instrumentResponse = await api.get(`instrument/${ticker}`);
        } else {
          instrumentResponse = await api.get(`instrument/${ticker}/${country}`);
        }
        if (Object.keys(instrumentResponse.data).length > 0) {
          setStock(instrumentResponse.data as Stock);
          if (instrumentResponse.data.fund) {
            isFund = true;
          }
        }
      } catch (error: unknown) {
        const data = (error as AxiosError)?.response?.data as { detail: string };
        setErrorMessage(data?.detail ?? 'An Unknown Error Occurred');
      } finally {
        setLoadedStock(true);
      }

      if (isFund) {
        try {
          let holdingsResponse;
          if (country === null) {
            holdingsResponse = await api.get(`holdings/${ticker}`);
          } else {
            holdingsResponse = await api.get(`holdings/${ticker}/${country}`);
          }
          if (Object.keys(holdingsResponse.data).length > 0) {
            setHoldings(holdingsResponse.data as Holdings);
          }
        } catch (error: unknown) {
          const data = (error as AxiosError)?.response?.data as { detail: string };
          setErrorMessage(data?.detail ?? 'An Unknown Error Occurred');
        } finally {
          setLoadedHoldings(true);
        }
      } else {
        setLoadedHoldings(true);
      }
    };

    getStockAndHoldings();

  }, [ticker, country]);

  useEffect(() => {
    const getIssueData = async () => {
      try {
        let issuesResponse;
        if (country === null) {
          issuesResponse = await api.get(`issues/${ticker}`);
        } else {
          issuesResponse = await api.get(`issues/${ticker}/${country}`);
        }
        if (Object.keys(issuesResponse.data).length > 0) {
          setIssues(issuesResponse.data as Issues);
        }
      } catch (error: unknown) {
        const data = (error as AxiosError)?.response?.data as { detail: string };
        setErrorMessage(data?.detail ?? 'An Unknown Error Occurred');
      } finally {
        setLoadedIssues(true);
      }
    };
    getIssueData();
  }, [ticker, country]);

  const countIssues = (issues: PositiveIssues | NegativeIssues) => {
    let count = 0;
    if (issues && Object.keys(issues).length > 0) {
      for (const issueName of Object.keys(issues)) {
        for (const ticker of Object.keys(issues[issueName])) {
          count += issues[issueName][ticker].length;
        }
      }
    }
    return count;
  };

  const getNegativeExposure = (holdings: Holdings) => {
    let exposure = 0;
    for (const holding of holdings) {
      if ((holding.score ?? 0) < 0) {
        exposure += holding.assetPercentage;
      }
    }
    return Math.round(exposure);
  };

  return (
    <>
    <div className='flex flex-col items-center px-4 md:px-10'>
      <div className="flex flex-col items-center w-full max-w-screen-xl">
        <header className="flex justify-between items-center py-4 w-full flex-wrap">
          <a href="/"><img src={logo} className="w-40 order-1" alt="logo" /></a>
          <div className='basis-full md:basis-2/3 order-3 md:order-2'>
            <SearchBar />
          </div>
          <a href="https://legacy.inspireinsight.com/login" className='order-2 md:order-3'>Dashboard</a>
        </header>
        <div className="w-full mt-10">
          <div data-cy="instrument-header" className='flex justify-between w-full flex-wrap'>
            {
              Object.keys(stock).length > 0 &&
              <div className='flex mb-2'>
                <div className='flex flex-col items-center bg-orange/10 border-orange/50 border rounded p-4'>
                  <ImpactScore score={stock.score} size="large" />
                  <div className='text-md font-bold text-orange'>inspire impact</div>
                </div>
                <div className='flex flex-col justify-center ml-6 geometric'>
                  <div className='text-5xl font-bold'>{ stock.ticker }</div>
                  <div className='text-3xl'>{ stock.name }</div>
                </div>
              </div>
            }
            <div className='flex mb-2'>
              {
                (stock.score ?? 0) > 0 &&
                Object.keys(issues).length > 0 &&
                <div className='bg-blue/10 border-blue/50 text-blue border rounded p-4 min-w-[9rem]'>
                  <div className='text-5xl font-thin geometric'>{ countIssues(issues.positiveIssues) }</div>
                  <div className='text-md condensed'>Positives</div>
                </div>
              }
              {
                (stock.score ?? 0) < 0 &&
                stock.fund === false &&
                Object.keys(issues).length > 0 &&
                <div className='bg-red/5 border-red/50 text-red border rounded p-4 min-w-[9rem]'>
                  <div className='text-5xl font-thin geometric'>{ countIssues(issues.negativeIssues) }</div>
                  <div className='text-md condensed'>Negatives</div>
                </div>
              }
              {
                (stock.score ?? 0) < 0 &&
                stock.fund === true &&
                holdings.length > 0 &&
                <div className='bg-red/5 border-red/50 text-red border rounded p-4 min-w-[9rem]'>
                  <div className='text-5xl font-thin geometric'>
                    { getNegativeExposure(holdings) }
                    <span className='text-3xl'> %</span>
                  </div>
                  <div className='text-md condensed'>Negative Exposure</div>
                </div>
              }
            </div>
          </div>

          {
            Object.keys(issues).length > 0 &&
            <div className='flex flex-col mt-6 mb-6 md:mt-16 bg-slate-50'>
              {
                (stock.score ?? 0) < 0 &&
                Object.keys(issues.negativeIssues).map((issueName: string) => {
                  return (
                    <NegativeIssueRow
                      key={issueName}
                      fund={stock.fund}
                      issueName={issueName}
                      issueDetails={issues.negativeIssues[issueName]}
                      selectedIssue={selectedIssue}
                      onClick={() => setSelectedIssue(selectedIssue === issueName ? '' : issueName)}
                    />
                  );
                })
              }
              {
                (stock.score ?? 0) > 0 &&
                Object.keys(issues.positiveIssues).map((issueName: string) => {
                  return (
                    <PositiveIssueRow
                      key={issueName}
                      fund={stock.fund}
                      issueName={issueName}
                      issueDetails={issues.positiveIssues[issueName]}
                      selectedIssue={selectedIssue}
                      onClick={() => setSelectedIssue(selectedIssue === issueName ? '' : issueName)}
                    />
                  );
                })
              }
            </div>
          }
          {
            (!loadedStock || !loadedIssues || !loadedHoldings) &&
            <div className='flex justify-center mt-8'>
              <img src={spinner} className="w-16" alt="spinner" />
            </div>
          }
          {
            loadedHoldings &&
            holdings.length > 0 &&
            <HoldingsTable holdings={holdings} />
          }
          {
            loadedStock &&
            Object.keys(stock).length === 0 &&
            <div>
              {errorMessage}
            </div>
          }
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

export default Instrument;
