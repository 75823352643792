import React from 'react';
import { renderArc } from '../utilities/svg';
import { useEffect } from 'react';

type Props = {
  size: number,
  score: number | null,
};

let count = 0;

const ScoreArc = (props: Props) => {
  const containerID: string = 'score-arc-' + count++;

  useEffect( () => {
    renderArc(props.score ?? 0, props.size, containerID);
  }, [props.score]);

  return (
    <div
      id={containerID}
      data-cy="score-arc"
      style={{
          display: 'inline-block',
          verticalAlign: 'top',
          width: props.size + 'px',
          height: props.size + 'px',
      }}
    ></div>
  );
};

export default ScoreArc;