import React from 'react';
import logo from '../../images/inspire-insight-logo.svg';
import spinner from '../../images/dual-ring-loading-grey.svg';
import Footer from '../../components/Footer';
import { useEffect, useState } from 'react';
import HomeData from '../../types/HomeData';
import Stock from '../../types/Stock';
import MiniTicker from '../../components/MiniTicker';
import SearchBar from '../../components/SearchBar';
import Banner from '../../components/Banner';
import api from '../../utilities/api';

const Home = () => {

  const [homeData, setHomeData] = useState({} as HomeData);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getHomeData = async () => {
      try {
        const response = await api.get('home');
        setHomeData(response.data as HomeData);
      } catch (error: unknown) {
        // do nothing
      } finally {
        setLoaded(true);
      }
    };
    getHomeData();
  }, []);

  return (
    <>
      <Banner title="" url="" linkText="" />
      <div className="flex flex-col items-center px-4 md:px-10">
        <div className="flex flex-col items-center w-full max-w-screen-xl">
          <header className="flex justify-between py-4 w-full">
            {/* <a href="https://legacy.inspireinsight.com/about">About</a> */}
            <div></div>
            <a href="https://legacy.inspireinsight.com/login">Dashboard</a>
          </header>
          <div className="flex flex-col items-center w-full py-8 mt-12">
            <img src={logo} className="w-72" alt="logo" />
          </div>
          <div className='max-w-[40rem] w-full'>
            <SearchBar />
          </div>
          {
            Object.keys(homeData).length > 0 &&
            <>
              <div className='py-4'>
                Biblical values data on <b>{ homeData.totalTickers && homeData.totalTickers.toLocaleString() }</b> tickers
              </div>
              <div className="flex justify-evenly mt-12 w-full flex-wrap">
                {
                  homeData.categories &&
                  Object.keys(homeData.categories).map((category: string) => {
                    return (
                      <div key={ category } className='flex flex-col px-2 mb-4 basis-full sm:basis-1/2 md:basis-1/4'>
                        <div className="text-sm mb-2.5 condensed text-black/85">
                          { category }
                        </div>
                        {
                          homeData.categories[category].map((stock: Stock, index: number) => {
                            return <MiniTicker key={ index } {...stock} />;
                          })
                        }
                      </div>
                    );
                  })
                }
              </div>
            </>
          }
          {
            !loaded &&
            Object.keys(homeData).length === 0 &&
            <img src={spinner} className="w-16" alt="spinner" />
          }
          {
            loaded &&
            Object.keys(homeData).length === 0 &&
            <div className='pt-4'>
              Could not load data. Please try again later.
            </div>
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
